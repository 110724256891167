// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Defaults
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '@justinribeiro/lite-youtube';

Rails.start()
ActiveStorage.start()

// Noty
require('noty/lib/noty.css')
require('noty/lib/themes/mint.css')
import Noty from 'noty'
global.Noty = Noty

let zipInputs = document.querySelectorAll("input[name='order[billing_zip]'], input[name='order[shipping_zip]']")

zipInputs.forEach((elem) => {
  elem.addEventListener("input", (e) => {
    elem.value = elem.value.replace(/[^0-9]/g, '')
  })
})

